<!--
 * @Date: 2021-08-12 11:24:10
 * @LastEditors: YuWenYun
 * @LastEditTime: 2021-12-13 10:45:16
 * @Description: 
 * @version: 
 * @Author: YuWenYun
-->
<template>
  <svg
    :class="iconClass"
    aria-hidden="true"
    :style="{
      width: iconWidth + 'px',
      height: iconHeight + 'px',
      overflow: 'hidden',
      fill: 'currentColor',
      verticalAlign: '-0.15em'
    }"
  >
    <use :xlink:href="'#icon-' + iconName" />
  </svg>
</template>

<script>
export default {
  name: 'SvgIcon',
  props: {
    iconClass: {
      type: String
    },
    iconWidth: {
      type: Number,
      default: 10
    },
    iconHeight: {
      type: Number,
      default: 10
    },
    iconName: {
      type: String,
      required: true
    }
  }
};
</script>

<style></style>
