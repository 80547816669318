/*
 * @Date: 2021-11-09 18:05:13
 * @LastEditors: YuWenYun
 * @LastEditTime: 2022-03-28 17:46:51
 * @Description:
 * @version:
 * @Author: YuWenYun
 */
import request from '../../util/request';
const front = '/bff';
const service = '/martech-icme';
const serviceWk = '/martech-wk';
const serviceAgree = '/martech-aggregation';
const version = 'v1';
const BASE_URL = '/martech-basic-platform';
export default {
  // 获取h5数据信息
  getPageData(data, hideLoading) {
    return request.get({ url: `${front}${service}/h5leaflet/${version}/detail-h5/${data}`, hideLoading });
  },
  // 获取预览数据
  getPreviewData(data, hideLoading = false) {
    // /h5leaflet/{version}/preview-h5/{entityId}
    return request.get({ url: `${front}${service}/h5leaflet/${version}/preview-h5/${data}`, hideLoading });
  },
  // 获取短信验证码
  getMessageVerify(data) {
    return request.get({ url: `${front}${service}/${version}/captcha/customer/real-info`, params: data });
  },
  // 提交留资组件表单数据
  submitFormData(data) {
    return request.post({
      url: `${front}${service}/h5leaflet/${version}/saveH5Submit`,
      formate: false,
      data
    });
  },
  // 正式作品投票的详细 以及投票的人的信息
  getUserVote(data) {
    return request.get({
      url: `${front}${service}/${version}/landing-page/vote-data`,
      params: data
    });
  },
  // 投票
  toVote(data) {
    return request.post({
      url: `${front}${service}/h5vote/${version}/toVote`,
      formate: false,
      data
    });
  },
  // 新版H5页面数据
  getContentDetail(id) {
    return request.get({ url: `${front}${service}/${version}/landing-pages/${id}` });
  },
  // 获取授权的参数
  getParams(params) {
    return request.get({ url: '/bff/api/getParams', params, hideLoading: true });
  },
  // 获取省市区
  areaOptionsAPI(regionLevel = 2) {
    return request.get({
      url: `${front}${BASE_URL}/basic/region/${version}/region-tree`,
      params: { regionLevel }
    });
  },
  // 提交
  submit(data) {
    // /v1/landing-pages/forms/submitter
    return request.post({
      url: `${front}${service}/${version}/landing-pages/data/submitter`,
      data
    });
  },
  //上报分享信息
  postVisitor(data) {
    return request.post({ url: `${front}${service}/${version}/landing-pages/browser`, data, hideLoading: true });
  },
  //上报分享信息
  postShare(data) {
    return request.post({ url: `${front}${service}/${version}/landing-pages/share-reporter`, data, hideLoading: true });
  },
  //上报地理位置信息
  postAddr(data) {
    return request.post({ url: `${front}${service}/${version}/customer/address-reporter`, data, hideLoading: true });
  },
  getCard(data) {
    return request.post({ url: `${front}${service}/${version}/customer/shareCard`, data, hideLoading: true });
  },
  getWkCard(params) {
    return request.get({ url: `${front}${serviceWk}/share/${version}/ai-card`, params, hideLoading: true });
  },
  getAllCard(data) {
    return request.post({ url: `${front}${serviceAgree}/card/${version}/lex-card`, data, hideLoading: true });
  },
};
