<!--
 * @Date: 2021-08-13 17:27:13
 * @LastEditors: YuWenYun
 * @LastEditTime: 2021-08-20 15:32:51
 * @Description: 启动页
 * @version: 
 * @Author: YuWenYun
-->
<template>
  <!---------------启动页--------------->
  <div :style="{ backgroundColor: commonConfig.bgObj.bgColor, backgroundImage: commonConfig.bgObj.name !== 'bg0' ? commonConfig.bgObj.url : '' }" class="cm-mask" style="z-index: 9998;width:100%;height:100%"></div>
</template>

<script>
export default {
  name: 'starPage',
  props: {
    commonConfig: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  mounted() {
    // console.log( this.commonConfig,'12312312');
  }
};
</script>

<style></style>
