// import trackFn from '@/util/trackFn';
import { initWxShareData } from '@/util/util';
import { getShareDefaultImg } from '@/util/enum';
import Content from '@/api/content';

class wxSdk {
  constructor(jsApiList, { params, baseConfig, wxSign, user, content }) {

    this.cmeId = params.cmeId || '';
    this.type = params.type || '';
    this.estateId = params.estateId || '';
    this.openId = user.openId || '';
    this.nickName = user.nickName || '';
    this.unionId = user.unionId || '';
    this.shareImg = content.shareImg || getShareDefaultImg[params.type].img;
    this.shareTitle = content.shareTitle || getShareDefaultImg[params.type].title;
    this.shareDesc = content.shareDesc || '';
    this.sharePathNew = baseConfig.sharePathNew || '';
    this.params = params;

    wx.config({
      ...wxSign,
      jsApiList,
      openTagList: ['wx-open-launch-weapp'],
      // debug: true
    });
    wx.ready(async () => {
      this.initWx();
      // wx.getLocation({
      //   type: 'wgs84', // 默认为wgs84的gps坐标，如果要返回直接给openLocation用的火星坐标，可传入'gcj02'
      //   success: res => {
      //     console.log('获取地址位置成功', res);
      //     if (res.latitude && res.longitude) {
      //       if (res.errMsg) {
      //         delete res.errMsg;
      //       }
      //       let data = {
      //         cmeId: this.cmeId,
      //         ...res
      //         // latitude: res.latitude, // 纬度，浮点数，范围为90 ~ -90
      //         // longitude: res.longitude, // 经度，浮点数，范围为180 ~ -180。
      //         // speed: res.speed, // 速度，以米/每秒计
      //         // accuracy: res.accuracy  // 位置精度
      //       };
      //       console.log('data', data);
      //       Content.postAddr(data);
      //     }
      //   },
      //   cancel: res => {
      //     console.log('用户拒绝授权获取地理位置' || res);

      //   },
      //   fail: error => {
      //     console.log('用户权获取地理位置error' || error);
      //   },
      //   complete: () => {
      //     console.log('用户权获取地理位置complete');
      //   }
      // });
    })
  }
  initWx(shareData) {
    console.log('initWx shareData', shareData);
    if (!shareData) {
      shareData = {
        shareOpenId: this.openId, shareUserName: this.nickName, estateId: this.estateId, cmeId: this.cmeId, sharedPeopleId: this.unionId
      }
      if (this.params.shareTraceId) {
        shareData.shareTraceId = this.params.shareTraceId
      }
    }
    shareData = initWxShareData({ ...shareData, sharePathNew: this.sharePathNew, type: this.type }, this.params);
    console.log('shareData', shareData);
    wx.onMenuShareTimeline({
      title: this.shareTitle, // 分享标题
      desc: this.shareDesc, // 分享描述
      link: shareData.shareUrl, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
      imgUrl: this.shareImg, // 分享图标
      success: async () => {
        console.log('onMenuShareTimeline')
        try {
          this.initWx(shareData);
          await Content.postShare(shareData); // 推送地理位置
          window.TrackEvent.contentShareTrace('MOMENTS');
          window.Redpacks.onTrigger({ behCode: ['BEH-SHARE'] })
        } catch (e) {
          console.log(e);
        }

      }
    });
    // 发送给微信好友
    wx.onMenuShareAppMessage({
      title: this.shareTitle, // 分享标题
      desc: this.shareDesc, // 分享描述
      link: shareData.shareUrl, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
      imgUrl: this.shareImg, // 分享图标
      success: async () => {
        // 成功
        // 放这里面，不要影响主流程
        // 上报要放前面，要不然会location.reload会先执行，上报不了分享数据
        // trackFn[shareFuncType](data, info, 'FRIEND'); // 埋点 分享触发(shareFuncType为trackFn.js内具体调用的分享函数名)
        this.initWx(shareData);
        await Content.postShare(shareData); // 推送地理位置
        try {
          window.TrackEvent.contentShareTrace('FRIEND');
          window.Redpacks.onTrigger({ behCode: ['BEH-SHARE'] })
        } catch (error) {
        }
      }
    });
  }
  ready() {
    return new Promise((res, rej) => {
      wx.ready(() => {
        res(true)
      })
      wx.error(() => {
        rej('wxready失败')
      })
    });
  }

}
export default wxSdk;
