<template>
  <div class="error">
    <div class="error-img"></div>
    <div class="error-msg">{{ errMsg }}</div>
  </div>
</template>

<script>
export default {
  name: 'error',
  props: {
    errMsg: {
      type: String,
      default: ''
    }
  }
};
</script>

<style lang="less" scoped>
.error {
  padding-top: 30vh;
}
.error-img {
  width: 120px;
  height: 126px;
  background: url('https://martech.oss-cn-beijing.aliyuncs.com/crm/nfile/crm/20220325150202-7cd97564-6acf-4e1b-8512-ffc6df182ec6.png');
  background-size: cover;
  margin: 0 auto;
}
.error-msg {
  margin-top: 14px;
  font-size: 14px;
  font-weight: 400;
  color: #666666;
  text-align: center;
}
</style>
