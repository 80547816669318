var render = function render(){var _vm=this,_c=_vm._self._c;return _c('svg',{class:_vm.iconClass,style:({
    width: _vm.iconWidth + 'px',
    height: _vm.iconHeight + 'px',
    overflow: 'hidden',
    fill: 'currentColor',
    verticalAlign: '-0.15em'
  }),attrs:{"aria-hidden":"true"}},[_c('use',{attrs:{"xlink:href":'#icon-' + _vm.iconName}})])
}
var staticRenderFns = []

export { render, staticRenderFns }