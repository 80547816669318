import sensors from 'wxb-web-sdk';
import { deviceInfo, screenType } from '@/util/util';
// 埋点字段文档 https://docs.qq.com/sheet/DV1VQclB4Y1lnYUho?tab=lisy9f
// content_type	内容类型 枚举值：文章(article), 表单(form), 海报:(poster), 微传单(h5), 盖楼活动(building), 活动红包(packet)
export default {
  // 埋点 初始化
  initTrace(paramsData, resultObj) {
    const { opId, tenantId, estateId, sourcePlatform = '' } = paramsData.params;
    const cdpData = paramsData.cdp || {};
    var returnCitySN = window.returnCitySN || {}; // 防止掊拿不到数据报错
    // 公共字段
    const comData = {
      // distinctId: paramsData.user.unionId, // 用户唯一ID 大米说这个字段评审时已废弃
      time: new Date().getTime(), // 时间戳
      ip: returnCitySN['cip'], // ip
      province: resultObj && resultObj.province ? resultObj.province : null,
      manufacturer: null, // 设备制造商
      carrier: null, // 运营商
      city: resultObj && resultObj.city ? resultObj.city : null,
      screen_width: window.screen.width,  // 屏幕高度
      screen_height: window.screen.height, // 屏幕宽度
      wifi: resultObj.wifi === 'wifi', // 是否 WIFI
      network_type: resultObj.wifi, // 网络类型
      model: deviceInfo.deviceType, // 设备型号
      os: deviceInfo.OS, // 操作系统
      os_version: deviceInfo.OSVersion, // 操作系统版本
      screen_orientation: screenType[deviceInfo.orientation], // 屏幕方向
      browser: deviceInfo.browserInfo.browser, // 浏览器
      browser_version: deviceInfo.browserInfo.browserVersion, // 浏览器版本
      name: paramsData.user.nickName,
      corp_id: opId || null,  // 运营主体ID
      renter_id: tenantId || null, // 租户ID
      project_id: estateId || null,  // 项目ID
      source_platform: sourcePlatform,  // 平台来源
      saler_id: paramsData.params.saleIdOld,//置业顾问id
      ...cdpData //cdp接口返回什么就上报什么
    };

    sensors.jsonInit({
      server_url: paramsData.baseConfig.pointUrl,
      is_heart_beat: true, //是否需要循环心跳（浏览时长补充）
      // heart_beat_time: 2000,//循环心跳时间间隔，单位ms
      // server_url: 'https://secretdata.test.wangxiaobao.com/dc/api/v1/report',
      distinct_id: paramsData.user.unionId,
      // show_log: true,
      project: paramsData.baseConfig.pointName
    });
    // 使用 setProfile() 设置用户属性:is_login_id 标记 distinct_id 是否为登录id
    sensors.setProfile(paramsData.user.unionId, true, {});
    /**
     * @description 设置公共属性
     * @param {*} props object，必传
     */
    sensors.registerPage({
      ...comData
    });
  },

  // 文章分享
  articleShareTrace(paramsData, info, type) {
    // 触发埋点
    sensors.track('article_share', {
      province: paramsData.user.province,
      city: paramsData.user.city,
      article_id: info.id,
      article_title: info.title,
      trace_id: paramsData.sessionId,
      share_type: type,
      share_depth: paramsData.params.depth || null
    });
  },

  // 文章浏览
  articleViewTrace(paramsData, info, cb) {
    sensors.track('article_read', {
      province: paramsData.user.province,
      city: paramsData.user.city,
      article_id: info.id,
      article_title: info.title,
      trace_id: paramsData.sessionId,
      url_path: window.location.hash.split('?')[0],
      referrer_host: null,
      referrer: null,
      saler_id: paramsData.params.saleIdOld || null,
      share_trace_id: paramsData.params.shareTraceId || null,
      duration_times: 0,//info.durationTimes ? info.durationTimes : null,
      share_person: paramsData.params.sharedPeopleId ? paramsData.params.sharedPeopleId : null,
      is_finished: info.isFinished ? info.isFinished : null,
      // report_type: type // 枚举值：开始浏览（IN），结束浏览（OUT）
    }, (id) => {
      cb(id);
    });
  },



  // 表单分享
  formShareTrace(paramsData, info, type) {
    sensors.track('form_share', {
      province: paramsData.user.province,
      city: paramsData.user.city,
      form_id: info.id,
      form_title: info.formName,
      trace_id: paramsData.sessionId,
      share_type: type,
      share_depth: paramsData.params.depth || null
    });
  },

  // 表单浏览
  formViewTrace(paramsData, info, type) {
    sensors.track('form_read', {
      province: paramsData.user.province,
      city: paramsData.user.city,
      form_id: info.id,
      form_title: info.formName,
      trace_id: paramsData.sessionId,
      url_path: window.location.hash.split('?')[0],
      referrer_host: null,
      referrer: null,
      saler_id: paramsData.params.saleIdOld || null,
      share_trace_id: paramsData.params.shareTraceId || null,
      duration_times: 0,// info.durationTimes,
      share_person: paramsData.params.sharedPeopleId ? paramsData.params.sharedPeopleId : null,
      // report_type: type // 枚举值：开始浏览（IN），结束浏览（OUT）
    });
  },

  // 表单提交
  formSubmitTrace(info) {
    // 动态获取
    let paramsData = JSON.parse(sessionStorage.getItem('USER-PARAMS-DATA'));
    sensors.track('form_submit', {
      province: paramsData.user.province,
      city: paramsData.user.city,
      form_id: info.id,
      form_title: info.formName,
      trace_id: paramsData.sessionId,
      formsub_id: info.id
    });
    //顺带提交内容留资
    sensors.track('content_submit', {
      content_id: info.id,
      content_title: info.formName,
      content_type: 'form',
      trace_id: paramsData.sessionId,
      contentsub_id: info.id
    });
  },

  // h5微传单 分享
  h5ShareTrace(paramsData, info, type) {
    sensors.track('leaflets_share', {
      province: paramsData.user.province,
      city: paramsData.user.city,
      leaflets_id: info.entityId,
      leaflets_title: info.title,
      trace_id: paramsData.sessionId,
      share_type: type,
      share_depth: paramsData.params.depth || null
    });
  },

  // h5微传单 浏览
  h5ViewTrace(paramsData, info, type) {
    sensors.track('leaflets_read', {
      province: paramsData.user.province,
      city: paramsData.user.city,
      leaflets_id: info.entityId,
      leaflets_title: info.title,
      trace_id: paramsData.sessionId,
      url_path: window.location.hash.split('?')[0],
      referrer_host: null,
      referrer: null,
      saler_id: paramsData.params.saleIdOld || null,
      share_trace_id: paramsData.params.shareTraceId || null,
      duration_times: 0,//info.durationTimes,
      share_person: paramsData.params.sharedPeopleId ? paramsData.params.sharedPeopleId : null,
      // report_type: type // 枚举值：开始浏览（IN），结束浏览（OUT）
    });
  },

  //  h5微传单 提交瘤子
  h5SubmitTrace(paramsData, info) {
    sensors.track('leaflets_submit', {
      province: paramsData.user.province,
      city: paramsData.user.city,
      leaflets_id: info.entityId,
      leaflets_title: info.title,
      trace_id: paramsData.sessionId
    });
    //顺带提交内容留资
    sensors.track('content_submit', {
      content_id: info.entityId,
      content_title: info.title,
      content_type: 'h5',
      trace_id: paramsData.sessionId
    });
  },

  // 视频 浏览
  videoViewTrace(paramsData, info, type) {
    sensors.track('video_watch', {
      province: paramsData.user.province,
      city: paramsData.user.city,
      video_id: info.videoId,
      video_title: info.title,
      trace_id: paramsData.sessionId,
      url_path: window.location.hash.split('?')[0],
      referrer_host: null,
      referrer: null,
      share_person: paramsData.params.sharedPeopleId ? paramsData.params.sharedPeopleId : null,
      // report_type: type, // 枚举值：开始浏览（IN），结束浏览（OUT）
      // pv_confirm: type === 'IN' ? type : null,
      watch_times: info.watchTimes // 观看时常
    });
  },
  // 视频 转发
  videoShareTrace(paramsData, info, type) {
    sensors.track('video_share', {
      province: paramsData.user.province,
      city: paramsData.user.city,
      video_id: info.videoId,
      video_title: info.title,
      trace_id: paramsData.sessionId,
      url_path: window.location.hash.split('?')[0],
      referrer_host: null,
      referrer: null,
      saler_id: paramsData.params.saleIdOld || null,
      share_trace_id: paramsData.params.shareTraceId || null,
      duration_times: info.durationTimes,
      share_person: paramsData.params.sharedPeopleId ? paramsData.params.sharedPeopleId : null,
      report_type: type // 枚举值：开始浏览（IN），结束浏览（OUT）
    });
  },
  // 活动 转发
  activityShareTrace(paramsData, info, type) {
    sensors.track('activity_share', {
      province: paramsData.user.province,
      city: paramsData.user.city,
      activity_id: info.id,
      activity_title: info.title,
      trace_id: paramsData.sessionId,
      share_type: type,
      share_depth: paramsData.params.depth || null
    });
  },

  // 各种内容浏览OUT trackTypeName：上报事件名；paramsData： getInfo返回参数l; info:业务自己的id和title
  viewOutTrace(trackTypeName, paramsData, info) {
    sensors.initNavigatorOut(trackTypeName, {
      province: paramsData.user.province,
      city: paramsData.user.city,
      trace_id: paramsData.sessionId,
      url_path: window.location.hash.split('?')[0],
      referrer_host: null,
      referrer: null,
      saler_id: paramsData.params.saleIdOld || null,
      share_trace_id: paramsData.params.shareTraceId || null,
      share_person: paramsData.params.sharedPeopleId ? paramsData.params.sharedPeopleId : null,
      report_type: 'OUT', // 枚举值：开始浏览（IN），结束浏览（OUT）
      ...info
    }, 'duration_times', new Date().getTime());
  },
  //浏览完成事件
  viewComplete(paramsData, content, contentType) {
    sensors.track(
      'content_read_completed',
      {
        trace_id: paramsData.sessionId,
        content_title: content.title,
        content_type: contentType,
        content_id: content.id
      }
    );
  },
  // out追加属性方法
  viewOutTracePush(event, prop) {
    sensors.navigatorOutPushProp(
      event,
      {
        ...prop
      }
    )
  },

  // 浏览内容
  contentViewTrace(paramsData, info, type, contentType) {
    let id, title;
    switch (contentType) {
      case 'form':
        id = info.id;
        title = info.formName;
        break;
      case 'article':
        id = info.id;
        title = info.title;
        break;
      case 'h5':
        id = info.entityId;
        title = info.title;
        break;
      case 'video':
        id = info.videoId;
        title = info.title;
        break;
    }
    sensors.inViewPush('content_read', {
      province: paramsData.user.province,
      city: paramsData.user.city,
      content_id: id,
      content_title: title,
      content_type: contentType,
      referrer_host: null,
      referrer: null,
      trace_id: paramsData.sessionId,
      url_path: window.location.hash.split('?')[0],
      saler_id: paramsData.params.saleIdOld || null,
      share_trace_id: paramsData.params.shareTraceId || null,
      share_person: paramsData.params.sharedPeopleId ? paramsData.params.sharedPeopleId : null,
      // duration_times: 0,// info.durationTimes ? info.durationTimes : null,
      // pv_confirm: type === 'IN' ? type : null,
      // report_type: type // 枚举值：开始浏览（IN），结束浏览（OUT）
    });
  },

  // 浏览内容OUT
  // contentOutViewTrace(paramsData, info, contentType) {
  //   let id, title;
  //   switch (contentType) {
  //     case 'form':
  //       id = info.id;
  //       title = info.formName;
  //       break;
  //     case 'article':
  //       id = info.id;
  //       title = info.title;
  //       break;
  //     case 'h5':
  //       id = info.entityId;
  //       title = info.title;
  //       break;
  //     case 'activity':
  //       id = info.id;
  //       title = info.title;
  //       break;
  //     case 'video':
  //       id = info.videoId;
  //       title = info.title;
  //       break;
  //   }
  //   sensors.initNavigatorOut('content_read', {
  //     province: paramsData.user.province,
  //     city: paramsData.user.city,
  //     content_id: id,
  //     content_title: title,
  //     content_type: contentType,
  //     referrer_host: null,
  //     referrer: null,
  //     trace_id: paramsData.sessionId,
  //     url_path: window.location.hash.split('?')[0],
  //     saler_id: paramsData.params.saleIdOld || null,
  //     share_trace_id: paramsData.params.shareTraceId || null,
  //     share_person: paramsData.params.sharedPeopleId ? paramsData.params.sharedPeopleId : null,
  //     duration_times: info.durationTimes ? info.durationTimes : null,
  //     pv_confirm: null,
  //     report_type: 'OUT' // 枚举值：开始浏览（IN），结束浏览（OUT）
  //   }, 'duration_times', new Date().getTime());
  // },

  // 转发内容
  contentShareTrace(paramsData, info, type, contentType) {
    let id, title;
    switch (contentType) {
      case 'form':
        id = info.id;
        title = info.formName;
        break;
      case 'article':
        id = info.id;
        title = info.title;
        break;
      case 'h5':
        id = info.entityId;
        title = info.title;
        break;
      case 'video':
        id = info.videoId;
        title = info.title;
        break;
    }
    sensors.track('content_share', {
      count_key: `${paramsData.params.estateId}:${paramsData.user.unionId}:${paramsData.params.saleIdOld}:${id}`,
      province: paramsData.user.province,
      city: paramsData.user.city,
      content_id: id,
      content_title: title,
      content_type: contentType,
      trace_id: paramsData.sessionId,
      saler_id: paramsData.params.saleIdOld || null,
      share_type: type,
      share_depth: paramsData.params.depth || null
    });
  },

  // 按钮点击事件 打电话
  buttonClickTrace(paramsData, info, contentType, btnType) {
    let id_, title_, buttonId, buttonTitle;
    switch (contentType) {
      case 'form':
        id_ = info.id;
        title_ = info.formName;
        break;
      case 'article':
        id_ = info.id;
        title_ = info.title;
        break;
      case 'h5':
        id_ = info.entityId;
        title_ = info.title;
        break;
      case 'activity':
        id_ = info.id;
        title_ = info.title;
        break;
      case 'video':
        id_ = info.videoId;
        title_ = info.title;
        break;
    }
    switch (btnType) {
      case 'call':
        buttonId = 'button_bucard_call';
        buttonTitle = '名片_打电话';
        break;
      case 'wx':
        buttonId = 'button_bucard_wx';
        buttonTitle = '名片_加微信';
        break;
      case 'chat':
        buttonId = 'button_bucard_chat';
        buttonTitle = '名片_聊一聊';
        break;
    }
    sensors.track('button_click', {
      province: paramsData.user.province,
      city: paramsData.user.city,
      trace_id: paramsData.sessionId,
      button_id: buttonId,
      button_title: buttonTitle,
      content_id: id_,
      content_title: title_,
      content_type: contentType,
    });
  }
};
