<template>
  <div class="card-component" id="card-component">
    <div class="card-box-wrap">
      <div>
        <div class="img-box">
          <img :src="cardInfo.avatar" alt="" v-if="cardInfo.avatar" />
          <img src="../assets/icons/img/base.png" alt="" v-else />
        </div>
        <span class="name">{{ cardInfo.cardName }}</span>
      </div>
      <div class="contact">
        <template v-if="!!cardInfo.cusServiceLink">
          <div class="chat" @click="toChat">
            <svg-icon icon-name="chat" :iconWidth="20" :iconHeight="20"></svg-icon>
            <span>聊一聊</span>
          </div>
          <div class="line"></div>
        </template>
        <div class="tel" :class="{ isMargin: !!cardInfo.cusServiceLink }" @click="handleTel">
          <svg-icon icon-name="tel" :iconWidth="20" :iconHeight="20"></svg-icon>
          <span>打电话</span>
        </div>
        <div class="line"></div>
        <div class="wx" @click="handleToWx">
          <svg-icon icon-name="wx" :iconWidth="20" :iconHeight="20"></svg-icon>
          <span>加微信</span>
        </div>
      </div>
    </div>
    <!-- 手机号弹窗 start -->
    <van-dialog className="confirm-dialog" v-model="telDialog.show" get-container="#app" :show-cancel-button="!!cardInfo.phone" :confirmButtonText="!!cardInfo.phone ? '确认' : '我知道了'" confirmButtonColor="#4D78FF" @confirm="cellPhone">
      <template v-if="!!cardInfo.phone">
        <div>
          <span>{{ cardInfo.cardName }}的手机号是{{ cardInfo.phone }}</span>
          <span>确认拨打？</span>
        </div>
      </template>
      <template v-else>
        <div><span>手机号为空</span></div>
      </template>
    </van-dialog>
    <!-- 手机号弹窗 end -->
    <!-- 微信弹窗 start -->
    <van-dialog className="wx-dialog" v-model="wxDialog.show" get-container="#app" :show-cancel-button="false" :show-confirm-button="false" :closeOnClickOverlay="true" confirmButtonColor="#4D78FF">
      <template>
        <div>
          <img :src="cardInfo.qwQrcode" alt="" />
          <span class="info">长按二维码，添加好友</span>
          <div class="button" @click="okThen">确定</div>
        </div>
      </template>
    </van-dialog>
    <!-- 微信弹窗 end -->
  </div>
</template>

<script>
import trackFn from '@/util/trackFn';

export default {
  name: 'card',
  data() {
    return {
      telDialog: {
        show: false
      },
      wxDialog: {
        show: false
      },
      showChat: true
    };
  },
  props: {
    cardInfo: {
      type: Object,
      default: function() {
        return {};
      }
    },
    paramsData: {
      type: Object,
      default: function() {
        return {};
      }
    },
    trackData: {
      type: Object,
      default: function() {
        return {};
      }
    },
    contentType: {
      type: String,
      default: 'article'
    }
  },
  methods: {
    // 拨打电话
    cellPhone() {
      if (this.cardInfo.phone) {
        window.location.href = 'tel://' + this.cardInfo.phone;
      }
    },
    okThen() {
      this.wxDialog.show = false;
    },
    // 聊一聊
    toChat() {
      window.location.href = this.cardInfo.cusServiceLink;
      // 上报聊一聊的按钮点击埋点
      trackFn.buttonClickTrace(this.paramsData, this.trackData, this.contentType, 'chat');
    },
    handleTel() {
      this.telDialog.show = true;
      // 上报打电话的按钮点击埋点
      trackFn.buttonClickTrace(this.paramsData, this.trackData, this.contentType, 'call');
    },
    handleToWx() {
      this.wxDialog.show = true;
      // 上报加微信的按钮点击埋点
      trackFn.buttonClickTrace(this.paramsData, this.trackData, this.contentType, 'wx');
    }
  }
};
</script>

<style lang="less" scoped>
.card-component {
  font-size: 10px;
  color: #606266;
  .card-box-wrap {
    background: #ffffff;
    box-shadow: 1px 1px 4px 2px rgba(171, 171, 171, 0.15);
    border-radius: 4px;
    padding: 16px;
    z-index: 1000;
    .img-box {
      display: inline-block;
      vertical-align: middle;
      img {
        width: 60px;
        height: 60px;
        border-radius: 6px;
      }
    }
    .name {
      font-weight: 600;
      color: rgba(0, 0, 0, 0.8);
      font-size: 14px;
      margin-left: 16px;
    }
    .contact {
      font-weight: 400;
      font-size: 12px;
      color: #606266;
      display: flex;
      align-items: center;
      margin-top: 16px;
      .wx {
        margin-left: 24px;
        span {
          margin-left: 4px;
          vertical-align: 3px;
        }
      }
      .tel {
        margin-right: 24px;
        span {
          margin-left: 4px;
          vertical-align: 3px;
        }
      }
      .chat {
        margin-right: 24px;
        span {
          margin-left: 4px;
          vertical-align: 3px;
        }
      }
      .line {
        width: 2px;
        height: 14px;
        background-color: rgba(77, 120, 255, 0.1);
      }
    }
    .isMargin {
      margin-left: 24px;
    }
  }
}
.confirm-dialog {
  font-size: 16px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.6);
  text-align: center;
  div {
    margin: 44px;
  }
  span {
    display: block;
    &:nth-child(2) {
      margin-top: 8px;
    }
  }
}
.wx-dialog {
  width: 300px;
  height: 360px;
  background: #ffffff;
  border-radius: 4px;
  text-align: center;
  font-size: 14px;
  img {
    width: 176px;
    height: 176px;
    margin-top: 44px;
  }
  .info {
    font-weight: 400;
    color: #606266;
    display: block;
    margin-top: 16px;
    margin-bottom: 24px;
  }
  .button {
    width: 252px;
    height: 48px;
    line-height: 48px;
    background: #4d78ff;
    border-radius: 4px;
    font-weight: 500;
    color: #ffffff;
    font-size: 16px;
    margin-left: 24px;
  }
}
</style>
