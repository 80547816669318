/*
 * @Author: your name
 * @Date: 2020-12-03 10:27:37
 * @LastEditTime: 2022-03-30 09:36:31
 * @LastEditors: YuWenYun
 * @Description: In User Settings Edit
 * @FilePath: \online-sale-h5\src\util\util.js
 */
import {
  getDeviceInfo
} from '@zstark/device';
import md5 from 'js-md5';

// 文档 https://www.npmjs.com/package/@zstark/device
// device.getDeviceInfo()
/*
{
  "deviceType": "PC",
  "OS": "Windows",
  "OSVersion": "10.0",
  "language": "zh-CN",
  "orientation": "h",
  "browserInfo": {
    "info": "Chrome(版本: 91.0.4472.114, 内核: Blink)",
    "browser": "Chrome",
    "engine": "Blink",
    "browserVersion": "91.0.4472.114"
  }
}
*/

// 获取地理位置
export function getLocation() {
  return new Promise((resolve, reject) => {
    wx.getLocation({
      type: 'wgs84', // 默认为wgs84的gps坐标，如果要返回直接给openLocation用的火星坐标，可传入'gcj02'
      success: function (res) {
        let obj = {
          latitude: res.latitude, // 纬度，浮点数，范围为90 ~ -90
          longitude: res.longitude, // 经度，浮点数，范围为180 ~ -180。
          speed: res.speed, // 速度，以米/每秒计
          accuracy: res.accuracy, // 位置精度
        };
        resolve(obj);
      },
      fail: (err) => {
        reject(err);
      },
      cancel: (err) => {
        reject(err);
      },
    });
  });
}

// 获取设备
export const deviceInfo = getDeviceInfo();
// 屏幕
export const screenType = {
  v: "竖屏",
  h: "横屏"
};

const ua = navigator.userAgent;
// Mozilla/5.0 (iPhone; CPU iPhone OS 10_3_2 like Mac OS X) AppleWebKit/603.2.4 (KHTML, like Gecko) Mobile/14F89
const isPad = () => ua.indexOf('iPad') > -1;
const isIpod = () => ua.indexOf('iPod') > -1;
const iphone = () => ua.indexOf('iPhone') > -1;

export const isAndroid = () => ua.indexOf('Android') > -1 || ua.indexOf('Linux') > -1;
export const isIos = () => isPad() || isIpod() || iphone();
export const getRoleFlag = () => {
  const role = JSON.parse(localStorage.getItem('crmRole')) || {};
  return role.saleRoleName && role.saleRoleName === '置业顾问' ? 0 : 1;
};
/**
 * 解析hash模式URL传参
 * @param {Object} key
 */
export function getQueryString(key) {
  var after = window.location.search;
  //key存在先通过search取值如果取不到就通过hash来取
  after = window.location.hash.split('?')[1] || after.substr(1);
  if (after && after !== '') {
    var reg = new RegExp(`(^|&)${key}=([^&]*)(&|$)`);
    var r = after.match(reg);
    if (r != null) {
      return decodeURIComponent(r[2]);
    } else {
      return '';
    }
  }
}
// 获取当前操作系统
// export const getOS = () => {
//   let os;
//   if (navigator.userAgent.indexOf('Android') > -1 || navigator.userAgent.indexOf('Linux') > -1) {
//     os = 'Android';
//   } else if (navigator.userAgent.indexOf('iPhone') > -1) {
//     os = 'iOS';
//   } else if (navigator.userAgent.indexOf('Windows Phone') > -1) {
//     os = 'WP';
//   } else {
//     os = 'Others';
//   }
//   return os;
// }

// 获取操作系统版本
// export const getOSVersion = () => { // 获取操作系统版本
//   let OSVision = '1.0';
//   const u = navigator.userAgent;
//   let isAndroid = u.indexOf('Android') > -1 || u.indexOf('Linux') > -1; //Android
//   let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
//   if (isAndroid) {
//     OSVision = navigator.userAgent.split(';')[1].match(/\d+\.\d+/g)[0];
//   }
//   if (isIOS) {
//     OSVision = navigator.userAgent.split(';')[1].match(/(\d+)_(\d+)_?(\d+)?/)[0];
//   }
//   return OSVision;
// }

// 获取横竖屏状态
// export const getOrientationStatu = () => { // 获取横竖屏状态
//   let orientationStatu;
//   if (window.screen.orientation.angle == 180 || window.screen.orientation.angle == 0) { // 竖屏
//     orientationStatu = '竖屏';
//   }
//   if (window.screen.orientation.angle == 90 || window.screen.orientation.angle == -90) { // 横屏
//     orientationStatu = '横屏';
//   }
//   return orientationStatu;
// }

// export const getExploreName = () => {
//   let userAgent = navigator.userAgent;
//   if(userAgent.indexOf("Opera") > -1 || userAgent.indexOf("OPR") > -1){
//     return 'Opera';
//   }
//   else if(userAgent.indexOf("compatible") > -1 && userAgent.indexOf("MSIE") > -1){
//     return 'IE';
//   }
//   else if(userAgent.indexOf("Edge") > -1){
//     return 'Edge';
//   }
//   else if(userAgent.indexOf("Firefox") > -1){
//     return 'Firefox';
//   }
//   else if(userAgent.indexOf("Safari") > -1 && userAgent.indexOf("Chrome") == -1){
//     return 'Safari';
//   }
//   else if(userAgent.indexOf("Chrome") > -1 && userAgent.indexOf("Safari") > -1){
//     return 'Chrome';
//   }
//   else if(!!window.ActiveXObject || "ActiveXObject" in window){
//     return 'IE>=11';
//   }
//   else{
//     return 'unknown';
//   }
// }

// export const getDeviceModel = () => {
//   if(isAndroid) {
//     return 'Android';
//   } else if(isIos){
//     return 'IOS';
//   } else {
//     return 'Other';
//   }
// }

// 防抖函数
export function debounce(fn, delay) {
  let timer = null;
  return function () {
    if (timer) {
      clearTimeout(timer);
    }
    if (!timer) {
      fn.apply(this, arguments);
    }
    timer = setTimeout(() => {
      timer = null;
    }, delay);
  };
}

// 节流
export function throttle(fn, delay) {
  let begin = 0;
  return function () {
    let current = new Date().getTime();
    if (current - begin > delay) {
      fn.apply(this, arguments);
      begin = current;
    }
  };
}

/**
 * 生成签名
 * @param {Object} params 
 * @returns 
 */
export function creatSign(params = {}) {
  const arr = [];
  const arrStr = [];
  const salt = '55Oe7$g4#OGNOm1ikd';
  // 把key放在一个数组里面
  for (let item of Object.keys(params)) {
    arr.push(item);
  }
  // 数组重新排序
  arr.sort();
  // 重新组成参数
  for (let item of arr) {
    const value = params[item];
    arrStr.push(`${item}=${value}`);
  }
  const tempStr = `${arrStr.join('&')}${salt}`;
  return md5(tempStr);
}

// 获取当前浏览器参数
export function getUrlArgObject(paraName) {
  const url = document.location.toString();
  const arrObj = url.split('?');
  if (arrObj.length > 1) {
    const arrPara = arrObj[arrObj.length - 1].split('&');
    const obj = {};
    let arr = [];
    for (let i = 0; i < arrPara.length; i += 1) {
      arr = arrPara[i].split('=');
      if (arr.length !== 0 && arr[0] === paraName) {
        return decodeURIComponent(arr[1]);
      }
      if (arr.length !== 0) {
        obj[arr[0]] = decodeURIComponent(arr[1]);
      }
    }
    return obj;
  }
  return paraName ? '' : {};
}

export function initWxShareData({
  shareOpenId,
  cmeId,
  appType,
  sharePathNew,
  estateId,
  sharedPeopleId,
  shareUserName,
  shareTraceId,
  type,
  depth
}, query) {
  const qs = require('querystring');
  const shareId = md5(shareOpenId + '' + Date.now());
  if (shareTraceId) {
    shareTraceId = shareTraceId;
  } else {
    let md5TraceId = md5(shareOpenId + Date.now() + '1');
    shareTraceId = md5TraceId;
  }
  let cogntiveWay = '';
  switch (type) {
    case '20':
      cogntiveWay = 'COG-PAGE-FORM';
      break;
    case '10':
      cogntiveWay = 'COG-PAGE-ARTICLE';
      break;
    case '30':
      cogntiveWay = 'COG-PAGE-FLYER';
      break;
    case '50':
      cogntiveWay = 'COG-PAGE-VIDEO';
      break;
    default:
  }

  // 分享数据：
  let shareData = {
    shareId, // 分享id: MD5 openId + 时间戳
    cmeId,
    appType,
    sharedPeopleId,
    entityType: parseInt(type) >= 70 ? 2 : 1, // 类型 1内容 2活动
    shareOpenId, // 分享人ID
    shareUserName, // 分享人
    shareTraceId, //分享链路id
    type,
    depth: depth ? parseInt(depth) + 1 : 1 //分享链路数
  };
  if (query.estateId) {
    shareData.estateId = query.estateId;
  }
  if (query.opId) {
    shareData.opId = query.opId;
  }
  if (query.tenantId) {
    shareData.tenantId = query.tenantId;
  }
  if (query.saleId) {
    shareData.saleId = query.saleId;
  }
  if (query.appType) {
    shareData.appType = query.appType;
  }
  const shareUrl = `${sharePathNew}?${qs.stringify(shareData)}`;
  const extField = JSON.stringify({
    ...shareData,
    cogntiveWay,
    ...query
  });
  return {
    ...shareData,
    extField,
    shareUrl
  }
}


/**
 * 返回对应数据类型
 * @param opt
 * @returns {*} 对应的数据类型
 */
export function getDataType(opt) {
  let obj = {
    '[object Object]': 'object',
    '[object Array]': 'array',
    '[object String]': 'string',
    '[object Number]': 'number',
    '[object Null]': 'null',
    '[object Undefined]': 'undefined',
    '[object Function]': 'function',
    '[object Symbol]': 'symbol',
    '[object Boolean]': 'boolean',
    '[object Map]': 'map'
  };
  return obj[Object.prototype.toString.call(opt)];
}
