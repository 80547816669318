<!--
 * @Date: 2022-03-21 16:13:06
 * @LastEditors: YuWenYun
 * @LastEditTime: 2022-03-21 16:44:34
 * @Description: 
 * @version: 
 * @Author: YuWenYun
-->
<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "App",
  data() {
    return {
      focusTimer: null,
    };
  },
  methods: {
    handleScroll() {
      // window.scroll(0, 0);
      if (this.focusTimer) {
        clearTimeout(this.focusTimer);
        this.focusTimer = null;
      }
      document.body.scrollTop = 0;
    },
    //  处理ios中输入框键盘抬起后将页面顶起来后，不会弹的问题 
    initEd() {
      let userAgent = navigator.userAgent.toLowerCase();
      let isIos =
        userAgent.indexOf("iphone") != -1 || userAgent.indexOf("ipad") != -1;

      let focusinFn = () => {
        if (isIos) {
          document.body.addEventListener(
            "touchmove",
            (e) => {
              e.preventDefault();
            },
            {
              passive: false,
            }
          );
          window.addEventListener("scroll", this.handleScroll, true);
        }
      };
      let focusoutFn = () => {
        //软键盘收起的事件处理
        if (isIos) {
          let mo = function (e) {
            e.preventDefault();
          };
          document.body.style.overflow = ""; //出现滚动条
          document.removeEventListener("touchmove", mo, false);
          this.focusTimer = setTimeout(() => {
            window.removeEventListener("scroll", this.handleScroll, true);
          }, 400);
        }
      };
      document.body.addEventListener("focusin", focusinFn);
      document.body.addEventListener("focusout", focusoutFn);
    },
  },
  created(){
    this.initEd();
  }
};
</script>

<style lang="less" scoped>
* {
  -webkit-overflow-scrolling: touch;
}
#app {
  height: 100vh;
  //overflow: auto;
  // background-color: #F5F6F7;
  font-size: 14px;
}
</style>
<style type="text/css">
.icon {
  width: 1em;
  height: 1em;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}
</style>
