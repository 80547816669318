<!--
 * @Date: 2022-03-23 15:23:22
 * @LastEditors: YuWenYun
 * @LastEditTime: 2022-03-23 15:59:38
 * @Description: 
 * @version: 
 * @Author: YuWenYun
-->
<template>
  <div class="contact" @click="showDialog" v-if="cardInfo.avatar">
    <div class="btn">
      <div class="img-box">
        <img :src="cardInfo.avatar" alt="" v-if="cardInfo.avatar" />
        <img src="../assets/icons/img/base.png" alt="" v-else />
      </div>
    </div>
    <div class="context">
      <div style="width: 100%">聊一聊</div>
    </div>
    <van-dialog v-model="showCard" get-container="#app" :showConfirmButton="false" closeOnClickOverlay>
      <card-new />
    </van-dialog>
  </div>
</template>

<script>
  import { mapState } from 'vuex';
  export default {
    name: 'fixedButtonNew',
    data() {
      return {
        contact: false,
        showCard: false,
      };
    },
    computed: {
      ...mapState('app', ['cardInfo']),
    },
    methods: {
      showDialog() {
        this.showCard = true;
      },
    },
  };
</script>

<style lang="less" scoped>
.contact {
  position: fixed;
  right: 16px;
  bottom: 180px;
  overflow: hidden;
  z-index: 1000;

  .btn {
    text-align: center;
    .img-box {
      z-index: 2;
      position: relative;
      img {
        width: 48px;
        height: 48px;
        border-radius: 48px;
      }
    }
  }

  .context {
    padding: 1px 6px;
    font-size: 10px;
    background: #4d78ff;
    border-radius: 2px;
    color: #ffffff;
    text-align: center;
    z-index: 10;
    position: relative;
    margin-top: -8px;
  }

  &.hide {
    width: 40px;

    .btn {
      display: block;
    }

    .context {
      display: none;
    }
  }
}
</style>
