<template>
  <div class="contact" @click="showDialog">
    <div class="btn">
      <div class="img-box">
        <img :src="cardInfo.avatar" alt="" v-if="cardInfo.avatar" />
        <img src="../assets/icons/img/base.png" alt="" v-else />
      </div>
    </div>
    <div class="context">
      <div style="width: 100%;">聊一聊</div>
    </div>
    <van-dialog v-model="showCard" get-container="#app" :showConfirmButton="false" closeOnClickOverlay>
      <card :cardInfo="cardInfo" :paramsData="this.paramsData" :trackData="this.trackData" :contentType="this.contentType"></card>
    </van-dialog>
  </div>
</template>

<script>
export default {
  name: 'fixedButton',
  data() {
    return {
      contact: false,
      showCard: false
    };
  },
  props: {
    cardInfo: {
      type: Object,
      default: function() {
        return {};
      }
    },
    paramsData: {
      type: Object,
      default: function() {
        return {};
      }
    },
    trackData: {
      type: Object,
      default: function() {
        return {};
      }
    },
    contentType: {
      type: String,
      default: 'article'
    }
  },
  methods: {
    showDialog() {
      this.showCard = true;
    }
  }
};
</script>

<style lang="less" scoped>
.contact {
  position: fixed;
  right: 16px;
  bottom: 180px;
  overflow: hidden;

  .btn {
    text-align: center;
    .img-box {
      z-index: 2;
      position: relative;
      img {
        width: 48px;
        height: 48px;
        border-radius: 48px;
      }
    }
  }

  .context {
    padding: 1px 6px;
    font-size: 10px;
    background: #4d78ff;
    border-radius: 2px;
    color: #ffffff;
    text-align: center;
    z-index: 10;
    position: relative;
    margin-top: -8px;
  }

  &.hide {
    width: 40px;

    .btn {
      display: block;
    }

    .context {
      display: none;
    }
  }
}
</style>
