export const getShareDefaultImg = {
  '10': {
    img: 'https://osres.wangxiaobao.com/crm/nfile/cme/wenzhang.png',
    title: '我的文章'
  },
  '20': {
    img: 'https://osres.wangxiaobao.com/crm/nfile/cme/biaodan.png',
    title: '我的表单'
  },
  '30': {
    img: 'https://osres.wangxiaobao.com/crm/nfile/cme/weichuandan.png',
    title: '我的微传单'
  },
  '50': {
    img: 'https://osres.wangxiaobao.com/crm/nfile/cme/wenzhang.png',
    title: '我的视频'
  },
}

// 内容路由
export const contentTypeNew = {
  '10': '/#/articleNew/', // 文章
  '20': '/#/h5New/', // 表单
  '30': '/#/h5New/', //落地页
  '50': '/#/h5New/', // 视频
  '90': 'activity', //活动
};

//活动路由
export const actType = {
  'arrive_visit': 'hd/#/checkIn',
  'fission': 'hd/#/fission',
  'increase_traffic': 'hd/#/drainage',
  'no_threshold': 'hd/#/noThreshold',
  'new_no_threshold': 'hd/#/newNoThreshold',
  'building': 'hd/#/building',
  'treasure': 'hd/#/treasure/',
}