import sensors from 'wxb-web-sdk';
import { deviceInfo, screenType } from '@/util/util';
// 埋点字段文档 https://docs.qq.com/sheet/DV1VQclB4Y1lnYUho?tab=lisy9f
// content_type	内容类型 枚举值：文章(article), 表单(form), 海报:(poster), 微传单(h5), 盖楼活动(building), 活动红包(packet)
class TrackEvent {
  constructor(data) {
    this.content = data.content
    this.params = data.params;
    this.user = data.user;
    this.cdp = data.cdp;
    this.baseConfig = data.baseConfig;
    this.contentType = null;
    this.sessionId = data.sessionId;
    this.time = new Date().getTime(); // 时间戳
  }
  // 埋点 初始化
  initTrace() {
    const cdpData = this.cdp || {};
    console.log('cdpData', this.cdp);
    var returnCitySN = window.returnCitySN || {}; // 防止掊拿不到数据报错
    // 公共字段
    const comData = {
      ip: returnCitySN['cip'], // ip
      province: this.user.province,
      manufacturer: null, // 设备制造商
      carrier: null, // 运营商
      city: this.user.city || null,
      screen_width: window.screen.width,  // 屏幕高度
      screen_height: window.screen.height, // 屏幕宽度
      wifi: null, // 是否 WIFI
      network_type: null, // 网络类型
      model: deviceInfo.deviceType || null, // 设备型号
      os: deviceInfo.OS || null, // 操作系统
      os_version: deviceInfo.OSVersion || null, // 操作系统版本
      screen_orientation: screenType[deviceInfo.orientation] || null, // 屏幕方向
      browser: deviceInfo.browserInfo.browser || null, // 浏览器
      browser_version: deviceInfo.browserInfo.browserVersion || null, // 浏览器版本
      name: this.user.nickName || null,
      corp_id: this.user.opId || null,  // 运营主体ID
      renter_id: this.user.tenantId || null, // 租户ID
      project_id: this.params.estateId || null,  // 项目ID
      source_platform: this.params.sourcePlatform,  // 平台来源
      saler_id: this.params.saleId,
      ...cdpData //cdp接口返回什么就上报什么
    };

    console.log('comData', comData);

    sensors.jsonInit({
      server_url: this.baseConfig.pointUrl,
      is_heart_beat: true, //是否需要循环心跳（浏览时长补充）
      distinct_id: this.user.unionId,
      // show_log: true,
      project: this.baseConfig.pointName
    });
    // 使用 setProfile() 设置用户属性:is_login_id 标记 distinct_id 是否为登录id
    sensors.setProfile(this.user.unionId, true, {});
    /**
     * @description 设置公共属性
     * @param {*} props object，必传
     */
    sensors.registerPage({
      ...comData
    });
    switch (this.params.type) {
      case '20':
        this.contentType = 'form';
        break;
      case '10':
        this.contentType = 'article';
        break;
      case '30':
        this.contentType = 'h5';
        break;
      case '50':
        this.contentType = 'video';
        break;
      default:
    }
  }
  // 表单提交
  formSubmitTrace() {
    // 动态获取
    sensors.track('form_submit', {
      province: this.user.province,
      city: this.user.city,
      form_id: this.content.cmeId,
      form_title: this.content.title,
      trace_id: this.sessionId,
      formsub_id: this.content.cmeId
    });
    //顺带提交内容留资
    sensors.track('content_submit', {
      content_id: this.content.cmeId,
      content_title: this.content.title,
      content_type: 'form',
      trace_id: this.sessionId,
      contentsub_id: this.content.cmeId
    });
  }


  //  h5微传单 提交瘤子
  h5SubmitTrace() {
    sensors.track('leaflets_submit', {
      province: this.user.province,
      city: this.user.city,
      leaflets_id: this.content.cmeId,
      leaflets_title: this.content.title,
      trace_id: this.sessionId
    });
    //顺带提交内容留资
    sensors.track('content_submit', {
      content_id: this.content.cmeId,
      content_title: this.content.title,
      content_type: 'h5',
      trace_id: this.sessionId
    });
  }

  // 视频 浏览
  // videoViewTrace(paramsData, info, type) {
  //   sensors.track('video_watch', {
  //     province: this.user.province,
  //     city: this.user.city,
  //     video_id: info.videoId,
  //     video_title: info.title,
  //     trace_id: this.sessionId,
  //     url_path: window.location.hash.split('?')[0],
  //     referrer_host: null,
  //     referrer: null,
  //     share_person: paramsData.params.sharedPeopleId ? paramsData.params.sharedPeopleId : null,
  //     // report_type: type, // 枚举值：开始浏览（IN），结束浏览（OUT）
  //     // pv_confirm: type === 'IN' ? type : null,
  //     watch_times: info.watchTimes // 观看时常
  //   });
  // }

  //浏览完成事件
  viewComplete() {
    // sensors.browseComplete(data);
    console.log(' this.contentType', this.contentType);
    sensors.track(
      'content_read_completed',
      {
        trace_id: this.sessionId,
        content_title: this.content.title,
        content_type: this.contentType,
        content_id: this.content.cmeId
      }
    );
  }

  // 浏览内容上报
  contentViewTrace(cb) {
    sensors.inViewPush('content_read', {
      province: this.user.province,
      city: this.user.city,
      content_id: this.content.cmeId,
      content_title: this.content.title,
      content_type: this.contentType,
      referrer_host: null,
      referrer: null,
      time: this.time,
      trace_id: this.sessionId,
      url_path: window.location.hash.split('?')[0],
      saler_id: this.params.saleId || null,
      share_trace_id: this.params.shareTraceId || null,
      share_person: this.params.sharedPeopleId || null,
    }, id => {
      if (cb) {
        cb(id);
      }
    });
  }
  // 雷达
  contentRadarTrace() {
    sensors.track('content_radar', {
      content_id: this.content.cmeId,
      content_title: this.content.title,
      content_type: this.contentType,
      trace_id: this.sessionId,
      share_trace_id: this.params.shareTraceId || null,
      saler_id: this.params.saleId || null,
      share_person: this.params.sharedPeopleId || null,
    });
  }
  // 转发内容
  contentShareTrace(type) {
    sensors.track('content_share', {
      count_key: `${this.params.estateId}:${this.user.unionId}:${this.params.saleId}:${this.content.cmeId}`,
      province: this.user.province,
      city: this.user.city,
      content_id: this.content.cmeId,
      content_title: this.content.title,
      content_type: this.contentType,
      trace_id: this.sessionId,
      saler_id: this.params.saleId || null,
      share_type: type,
      share_depth: this.params.depth || null
    });
  }

  // 按钮点击事件 打电话
  buttonClickTrace(btnType) {
    let buttonId, buttonTitle;
    switch (btnType) {
      case 'call':
        buttonId = 'button_bucard_call';
        buttonTitle = `打电话`;
        break;
      case 'wx':
        buttonId = 'button_bucard_wx';
        buttonTitle = '加微信';
        break;
      case 'chat':
        buttonId = 'button_bucard_chat';
        buttonTitle = '聊一聊';
        break;
    }
    sensors.track('button_click', {
      province: this.user.province,
      city: this.user.city,
      trace_id: this.sessionId,
      button_id: buttonId,
      button_title: buttonTitle,
      content_id: this.content.cmeId,
      content_title: this.content.title,
      content_type: this.contentType,
    });
  }
}

export default TrackEvent;

