/*
 * @Date: 2021-08-12 11:24:10
 * @LastEditors: YuWenYun
 * @LastEditTime: 2021-12-20 19:40:08
 * @Description:
 * @version:
 * @Author: YuWenYun
 */
import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './vuex';
import Vant from 'vant';
import '@vant/touch-emulator';
import vueLazyload from 'vue-lazyload';
import 'vant/lib/index.less';
import './assets/styles/common.less';
import '@/assets/animate.css';
import '@/assets/h5.css';
import '../src/components/index.js';
import '@/assets/icons';
import './assets/styles/loading.less';
import * as utils from '@/util/util';
import { Popup } from 'vant';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import { VueHammer } from 'vue2-hammer';
import { bus } from '@/util/bus';
import IncentiveRedpacks from "wxb-more-incentive";

Vue.use(Popup);
Vue.use(ElementUI);
Vue.use(VueHammer);
window.Redpacks = new IncentiveRedpacks();
//全局组件注入
//移动端调试vconsole注入
import VConsole from 'vconsole';

if (utils.getQueryString('debug') && utils.getQueryString('debug') === '1') {
  new VConsole();
}
Vue.prototype.$bus = bus
Vue.use(Vant);
Vue.use(vueLazyload);
Vue.config.productionTip = false;
Vue.config.ignoredElements = ['wx-open-launch-weapp'];

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');

// 引入 skywalking
import ClientMonitor from 'wxb-skywalking-client-js';
const domain = location.hostname;
const oap = {
  test: 'https://sky.test.wangxiaobao.com',
  prod: 'https://sky.wangxiaobao.com'
}
let env = 'prod'
if (domain.indexOf('.test.') !== -1) {
  env = 'test'
}
else if (domain.indexOf('.dev.') !== -1) {
  env = 'dev'
}
else if (/^(localhost|(\d{1,3}\.){3}\d{1,3})$/i.test(domain)) {
  env = 'local'
}


if (oap[env]) {
  ClientMonitor.register({
    collector: oap[env],
    service: 'cme-h5', //前端静态项目的名称
    pagePath: 'index.html',
    serviceVersion: 'v1.0.0', //版本号（如果是要区分哪次前端页面版本，可以修改此参数，在错误日志管理里面该参数会有体现）
    vue: Vue,
    useFmp: true,
    traceTimeInterval: 10000, //上报频率（毫秒），无接口请求的时候是不会进行上报动作的，可根据自己项目接口请求情况自行调整频率
    enableSPA: true,
    noTraceOrigins: ['https://vectorsdk.map.qq.com', 'https://aegis.qq.com', 'https://mapstyle.qpic.cn'],

  });
}

function ClientError(msg) {
  this.name = 'CustomError';
  this.msg = msg;
}
ClientError.prototype = new Error();
window.ClientError = ClientError;
