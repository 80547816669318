/*
 * @Date: 2022-03-17 11:50:54
 * @LastEditors: YuWenYun
 * @LastEditTime: 2022-03-22 10:31:20
 * @Description: 
 * @version: 
 * @Author: YuWenYun
 */
export default [
  {
    path: '/h5New',
    component: () => import('@/views/newH5'),
  },
];
