import wxSdk from '@/util/wxSdk';
import TrackEvent from '@/util/trackEvent';
import { getUrlArgObject, getLocation } from '@/util/util';
import api from '@/api/content';
const state = {
  paramsData: {}, // 用户相关信息
  content: {}, //内容详情
  cardInfo: {}, //名片信息
  wxSdk: null
};

const mutations = {
  SET_PARAMS_DATA: (state, data) => {
    state.paramsData = data;
  },
  SET_CONTENT_DATA: (state, data) => {
    state.content = data;
  },
  SET_CARD_DATA: (state, data) => {
    state.cardInfo = data;
  },
  SET_WXSDK_DATA: (state, data) => {
    state.wxSdk = data;
  }
};
const actions = {
  //初始化页面
  async initPage({ dispatch, state }) {
    await dispatch('getParams');
    await dispatch('getContent');
    //如果是预览
    if (!state.paramsData.params.from) {
      if (!state.paramsData.user) {
        throw new ClientError('用户未授权');
      }
      dispatch('initWx', ['onMenuShareTimeline', 'onMenuShareAppMessage', 'getLocation', 'openLocation']);
      const trackEvent = new TrackEvent({ ...state.paramsData, content: state.content });
      trackEvent.initTrace();
      window.TrackEvent = trackEvent;
    }
    dispatch('reportVisitor');
    dispatch('initIncentive');
    return state.content;
  },
  initIncentive({ state }) {
    const data = {
      user: state.paramsData.user, // getParams接口返回的user
      params: state.paramsData.params, // getParams接口返回的params
      originUrl: '/bff/martech-marketing', // 营销平台的域名url
      // icon图标
      ext: {
        rewardWidth: 88, // 宽度
        rewardBottom: 50, // 定位距离页面底部的距离
        rewardRight: 40 // 定位距离页面右侧的距离
      }
    };
    window.Redpacks.config(data).then(async res => {
      //  {
      //     用户授权地理位置
      //     location: true, true需要 false不需要
      // }
      let location = {
        longitude: '', // 经度
        latitude: '' // 纬度
      };
      console.log('Redpacks res', res);
      if (res.location) {
        await state.wxSdk.ready();
        // 获取地理位置
        location = await getLocation();
        // }
        console.log('getLocation', location);
        // 用户授权地理位置success后调用
        window.Redpacks.getLocation(location);
      }
      if (getUrlArgObject().miniShare) {
        console.log('小程序点分享了');
        window.TrackEvent.contentShareTrace('FRIEND');
        window.Redpacks.onTrigger({ behCode: ['BEH-SHARE'] });
      }
    });
  },
  //获取参数
  async getParams({ commit }) {
    const queryData = getUrlArgObject();
    let params = await api.getParams({ url: window.location.href });
    if (params.params) {
      Object.assign(params.params, queryData);
    } else {
      params.params = queryData;
    }
    commit('SET_PARAMS_DATA', params);
  },
  //获取内容页面数据
  async getContent({ commit, state, dispatch }) {
    const content = await api.getContentDetail(state.paramsData.params.cmeId);
    commit('SET_CONTENT_DATA', content);
    if (content.hasCard && state.paramsData.params.saleId) {
      dispatch('getCard');
    }
  },
  async getCard({ commit, state }) {
    let cardData = {
      cmeId: state.paramsData.params.cmeId,
      cardId: state.paramsData.params.saleId,
      unionId: state.paramsData.user.unionId,
      nickName: state.paramsData.user.nickName,
      avter: state.paramsData.user.avatar,
      tenantId: state.paramsData.params.tenantId,
      opId: state.paramsData.params.opId,
      estateId: state.paramsData.params.estateId,
      role: 'ZYGW'
    };
    if (state.paramsData.params.shareId) {
      cardData['shareId'] = state.paramsData.params.shareId;
    }
    const content = await api.getAllCard(cardData);
    if (state.paramsData.params.sourcePlatform === 'WANGKE') {
      const wkContent = await api.getWkCard({
        saleId: state.paramsData.params.saleId,
        estateId: state.paramsData.params.estateId,
        opId: state.paramsData.params.opId
      });
      if (wkContent.url) {
        content['wkUrl'] = wkContent.url;
      }
    }
    console.log('content', content);
    commit('SET_CARD_DATA', content);
  },
  async getWkCard({ commit, state }) {
    const content = await api.getCard({
      cmeId: state.paramsData.params.cmeId,
      cardId: state.paramsData.params.saleId,
      extField: JSON.stringify(state.paramsData.params)
    });
    console.log('cardInfo', content);
    commit('SET_CARD_DATA', content);
  },
  //初始化微信
  initWx({ state, commit }, apiList) {
    console.log('initWx', state);
    //不带任何预览相关参数
    const wxsdk = new wxSdk(apiList, { ...state.paramsData, content: state.content });
    commit('SET_WXSDK_DATA', wxsdk);
  },
  //上报访客
  reportVisitor({ state }) {
    if (state.paramsData.params.appType) {
      let params = state.paramsData.params;
      //在线售楼特殊处理
      if (!params.estateId) {
        params.estateId = '0';
      }
      api.postVisitor({ ...params, extField: JSON.stringify(params) });
    }
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
